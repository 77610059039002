<template>
  <div>
    <BaseContainer class="pt-24 pb-16 relative z-20 min-h-screen">
      <BaseHeadingAndText
        heading="FAQ"
        headingSize="6xl"
        headingColor="cdlpink"
        textSize="xl"
      >
        Answers to questions you ask us the most.
      </BaseHeadingAndText>

      <BaseHeadingAndText
        heading="What is Cuddle?"
        headingSize="3xl"
        textSize="xl"
        class="pt-12"
      >
        Cuddle is a platform to launch impact NFTs - NFTs that are fun but also
        drive change!
      </BaseHeadingAndText>

      <BaseHeadingAndText
        heading="What is the #CUDL movement?"
        headingSize="3xl"
        textSize="xl"
        class="pt-12"
      >
        #CUDL is a movement to inspire the community to take action with social
        causes. We believe NFTs are one of the best ways to empower and
        incentivize communities to take massive real-world action.
      </BaseHeadingAndText>

      <BaseHeadingAndText
        heading="What are Cuddle Dogs?"
        headingSize="3xl"
        textSize="xl"
        class="pt-12"
      >
        Cuddle Dogs is the first NFT series of 3,000 cuddly doggos. At least 50%
        of the proceeds will be directed to the Impact Fund.
      </BaseHeadingAndText>

      <BaseHeadingAndText
        heading="What is the Impact Fund?"
        headingSize="3xl"
        textSize="xl"
        class="pt-12"
      >
        You can find more information about the fund <a href="https://medium.com/cuddlenft/introducing-the-impact-fund-259a0cf533c" target="_blank" class="underline">here</a>.
      </BaseHeadingAndText>

      <BaseHeadingAndText
        heading="What is the mint date?"
        headingSize="3xl"
        textSize="xl"
        class="pt-12"
      >
        The dates are yet to be revealed.
      </BaseHeadingAndText>

      <BaseHeadingAndText
        heading="How much is each Cuddle Dog?"
        headingSize="3xl"
        textSize="xl"
        class="pt-12"
      >
        The price is yet to be revealed.
      </BaseHeadingAndText>

      <BaseHeadingAndText
        heading="What is the best way to get involved?"
        headingSize="3xl"
        textSize="xl"
        class="pt-12"
      >
        Join our social media including:<br />
        <a class='underline' :href="uri.discord" target="_blank">Discord</a><br />
        <a class='underline' :href="uri.twitter" target="_blank">Twitter</a><br />
        <a class='underline' :href="uri.instagram" target="_blank">Instagram</a><br />
        <a class='underline' :href="uri.telegramMain" target="_blank">Telegram</a><br />
      </BaseHeadingAndText>
    </BaseContainer>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/common/Footer"
export default {
  name: "Faq",
  components: {
    Footer,
  },
  data() {
    return {
      uri: {
        discord: process.env.VUE_APP_URI_DISCORD,
        twitter: process.env.VUE_APP_URI_TWITTER,
        instagram: process.env.VUE_APP_URI_INSTAGRAM,
        medium: process.env.VUE_APP_URI_MEDIUM,
        telegramMain: process.env.VUE_APP_URI_TELEGRAM_MAIN,
        telegramAnnouncement: process.env.VUE_APP_URI_TELEGRAM_ANNOUNCEMENT,
      },
    }
  },
}
</script>
